export default {
  methods: {
    getLink(path, param) {
      const origin = window.location.origin;
      const url = `${origin}/${path}/${param}`;

      return url;
    },
    async copyLink(path, param) {
      try {
        const origin = window.location.origin;
        const url = `${origin}/${path}/${param}`;

        await navigator.clipboard.writeText(url);

        return url;
      } catch (error) {
        // this.linkCopied = false;
        console.log("Error copying url", error);
        return error;
      }
    },
    async genericCopy(item) {
      try {
        await navigator.clipboard.writeText(item);
        return item;
      } catch (error) {
        console.log("Error copying item", error);
        return error;
      }
    },
  },
};
