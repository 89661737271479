var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"mt-1 rs-report__content"},[_c('section',{class:_vm.pdfType ? 'mb-6' : 'mb-8 mb-lg-12'},[_c('header',{staticClass:"rs-report__main-title"},[_vm._t("mainTitle")],2),(!_vm.isMobile)?_c('v-divider',{class:[
        _vm.pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
        _vm.pdfType === 'print' ? 'grey' : 'white' ]},[_vm._v(" ")]):_vm._e(),_c('div',{staticClass:"mb-8 text-center rs-report__compare-anim",class:{ 'is-mobile': _vm.isMobile }},[(!_vm.isMobile)?_c('ReportCompareAnimation',{attrs:{"prospectScore":_vm.score,"averageScore":_vm.averageScore,"pdfType":_vm.pdfType,"age":_vm.age,"gender":_vm.gender}}):_vm._e(),(_vm.isMobile)?_c('ReportCompareAnimationMobile',{attrs:{"prospectScore":_vm.score,"averageScore":_vm.averageScore,"age":_vm.age,"gender":_vm.gender}}):_vm._e()],1),_c('div',{staticClass:"rs-bold-highlight",class:_vm.pdfType ? 'mb-6' : ''},[_vm._t("likelihood")],2)],1),_c('section',{class:_vm.pdfType ? 'mb-6' : 'mb-8 mb-lg-12'},[_c('header',{staticClass:"rs-report__secondary-title"},[_vm._t("factorsTitle")],2),(!_vm.isMobile)?_c('v-divider',{class:[
        _vm.pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
        _vm.pdfType === 'print' ? 'grey' : 'white' ]},[_vm._v(" ")]):_vm._e(),_c('v-row',[_vm._t("factorsBoxes")],2)],1),_c('section',{class:_vm.pdfType ? 'mb-6' : 'mb-8 mb-lg-12'},[_c('header',{staticClass:"rs-report__secondary-title"},[_vm._t("howMuchLoseTitle")],2),(!_vm.isMobile)?_c('v-divider',{staticClass:"mt-4",class:[
        _vm.pdfType ? 'mb-4' : 'mb-8',
        _vm.pdfType === 'print' ? 'grey' : 'white' ]},[_vm._v(" ")]):_vm._e(),_c('v-row',{staticClass:"align-center rs-report__howMuchLose"},[_c('v-col',{staticClass:"rs-bold-highlight",class:_vm.pdfType ? 'col-4' : 'col-12 col-md-4'},[_vm._t("howMuchLoseText")],2),_c('v-col',{class:_vm.pdfType ? 'col-8' : 'col-12 col-md-8'},[_vm._t("howMuchLoseGraphic")],2),_c('v-col',{staticClass:"col-12 mt-2 text-center"},[_c('div',{staticClass:"mx-auto",class:_vm.prospectOrigin === 'import' || _vm.isEditing ? 'mb-8' : '',staticStyle:{"max-width":"650px"}},[_vm._t("yourChoiceText")],2),(!_vm.pdfType)?_c('v-btn',{staticClass:"rs-report__cta",attrs:{"dark":"","x-large":"","elevation":"8","color":"primary","target":"_blank"},on:{"click":_vm.handleCta}},[_vm._t("ctaText")],2):_vm._e()],1)],1)],1),(_vm.advisor && !_vm.isEditing && !_vm.pdfType)?_c('section',{staticClass:"\n      d-flex\n      flex-column flex-md-row flex-wrap\n      align-center\n      justify-center\n    "},[_c('div',[_vm._v(_vm._s(_vm.advisor.companyName))]),(_vm.advisor.companyName && _vm.advisorAddressExists)?_c('v-icon',{staticClass:"d-none d-md-inline",attrs:{"color":"white"}},[_vm._v("mdi-circle-small")]):_vm._e(),(_vm.advisorAddressExists)?_c('div',[_c('address',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.advisor.address.streetAddress1 ? _vm.advisor.address.streetAddress1 : null)+_vm._s(_vm.advisor.address.streetAddress2 ? (", " + (_vm.advisor.address.streetAddress2)) : null)+_vm._s(_vm.advisor.address.city ? (", " + (_vm.advisor.address.city)) : null)+_vm._s(_vm.advisor.address.state ? (", " + (_vm.advisor.address.state)) : null)+_vm._s(_vm.advisor.address.postalCode ? (", " + (_vm.advisor.address.postalCode)) : null)+" ")])]):_vm._e(),(
        (_vm.advisor.companyName || _vm.advisorAddressExists) && _vm.advisor.companyUrl
      )?_c('v-icon',{staticClass:"d-none d-md-inline",attrs:{"color":"white"}},[_vm._v("mdi-circle-small")]):_vm._e(),_c('a',{attrs:{"href":_vm.advisor.companyUrl,"target":"_blank","title":"Advisor's Website"}},[_vm._v(_vm._s(_vm.formatCompanyUrl(_vm.advisor.companyUrl)))])],1):_vm._e(),(_vm.isEditing)?_c('section',{staticClass:"text-center"},[_vm._v(" ### ADVISOR COMPANY, ADDRESS, WEBSITE ### ")]):_vm._e(),(_vm.pdfType)?_c('div',{staticClass:"break-before"},[_vm._v(" ")]):_vm._e(),(!_vm.pdfType)?_c('v-divider',{class:[
      _vm.pdfType ? 'mb-6 mt-2' : 'mb-8 mt-4',
      _vm.pdfType === 'print' ? 'grey' : 'white' ]},[_vm._v(" ")]):_vm._e(),_c('section',{staticClass:"rs-report__extra-content",class:_vm.pdfType ? 'mb-6' : 'mb-8 mb-lg-12'},[_vm._t("extraContent")],2),_c('section',{staticClass:"rs-report__disclosure"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Disclosure")]),(!_vm.isEditing)?_c('p',[_vm._t("specificAdvisorDisclosure")],2):_vm._e(),(_vm.isEditing)?_c('p',[_vm._v("### ADVISOR SPECIFIC DISCLOSURE ###")]):_vm._e(),_c('p',[_vm._t("genericAdvisorDisclosure")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }