<template>
  <div>
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 385.4 359.2"
      enable-background="new 0 0 385.4 359.2"
      xml:space="preserve"
      :style="loading ? 'opacity: 0' : 'opacity: 1'"
    >
      <svg:style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600");
        text {
          font-family: "Inter";
          font-weight: bold;
        }
        .bold-text {
          font-weight: bold;
        }
      </svg:style>
      <g id="averageBlurb_00000038400211889434725940000002171242999513686943_">
        <path
          fill="#D9D9D9"
          d="M72.8,342.1h-1.7l5-13.7h1.7l5,13.7h-1.7L77,330.6h-0.1L72.8,342.1z M73.5,336.8h7v1.5h-7V336.8z"
        />
        <path
          fill="#D9D9D9"
          d="M91.8,331.9l-3.8,10.2h-1.6l-3.8-10.2h1.7l2.8,8.2h0.1l2.8-8.2H91.8z"
        />
        <path
          fill="#D9D9D9"
          d="M97.9,342.3c-1,0-1.8-0.2-2.6-0.7c-0.7-0.4-1.3-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8c0-1.1,0.2-2,0.6-2.8
		c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.5,1.5-0.7,2.4-0.7c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.8,0.9,1,1.6
		c0.3,0.7,0.4,1.5,0.4,2.4v0.7h-7.9v-1.4h6.3c0-0.6-0.1-1.1-0.3-1.5s-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4
		c-0.6,0-1.2,0.2-1.6,0.5c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1-0.4,1.6v0.9c0,0.8,0.1,1.4,0.4,2c0.3,0.5,0.6,0.9,1.1,1.2
		c0.5,0.3,1,0.4,1.7,0.4c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.2-0.2,0.4-0.5,0.6-0.9l1.5,0.4c-0.2,0.5-0.4,1-0.8,1.4
		c-0.4,0.4-0.8,0.7-1.4,0.9C99.2,342.2,98.5,342.3,97.9,342.3z"
        />
        <path
          fill="#D9D9D9"
          d="M104.5,342.1v-10.2h1.5v1.5h0.1c0.2-0.5,0.5-0.9,1-1.2c0.5-0.3,1-0.5,1.7-0.5c0.1,0,0.3,0,0.4,0
		c0.2,0,0.3,0,0.4,0v1.6c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
		c-0.2,0.4-0.3,0.8-0.3,1.2v6.5H104.5z"
        />
        <path
          fill="#D9D9D9"
          d="M114.5,342.4c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1-0.6-1.3-1.1c-0.3-0.5-0.5-1-0.5-1.7c0-0.6,0.1-1.1,0.3-1.4
		c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.4,1.3-0.5c0.5-0.1,0.9-0.2,1.4-0.3c0.6-0.1,1.1-0.1,1.5-0.2c0.4,0,0.7-0.1,0.9-0.2
		c0.2-0.1,0.3-0.3,0.3-0.5v-0.1c0-0.7-0.2-1.2-0.5-1.5s-0.9-0.5-1.6-0.5c-0.8,0-1.3,0.2-1.8,0.5c-0.4,0.3-0.7,0.7-0.9,1.1l-1.5-0.5
		c0.3-0.6,0.6-1.1,1.1-1.5c0.4-0.3,0.9-0.6,1.5-0.7c0.5-0.1,1.1-0.2,1.6-0.2c0.3,0,0.7,0,1.1,0.1s0.8,0.2,1.2,0.5
		c0.4,0.2,0.7,0.6,1,1.1c0.3,0.5,0.4,1.1,0.4,1.9v6.8h-1.6v-1.4h-0.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.3-0.6,0.5-1,0.6
		C115.6,342.3,115.1,342.4,114.5,342.4z M114.8,341c0.6,0,1.1-0.1,1.6-0.4s0.8-0.6,1-0.9c0.2-0.4,0.3-0.8,0.3-1.2V337
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.5,0.1-0.8,0.2c-0.3,0-0.6,0.1-0.8,0.1c-0.3,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.8,0.1-1.1,0.3
		c-0.4,0.1-0.6,0.3-0.9,0.5c-0.2,0.2-0.3,0.6-0.3,1c0,0.5,0.2,1,0.6,1.2C113.6,340.8,114.1,341,114.8,341z"
        />
        <path
          fill="#D9D9D9"
          d="M126.2,346.2c-0.8,0-1.4-0.1-2-0.3c-0.5-0.2-1-0.4-1.4-0.8c-0.4-0.3-0.7-0.6-0.9-1l1.3-0.9
		c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.5,0.5,0.9,0.6c0.4,0.2,0.9,0.3,1.5,0.3c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.8-1,0.8-1.9v-2.1H129
		c-0.1,0.2-0.3,0.4-0.5,0.7c-0.2,0.3-0.5,0.5-0.9,0.7c-0.4,0.2-0.9,0.3-1.6,0.3c-0.8,0-1.6-0.2-2.2-0.6c-0.7-0.4-1.2-1-1.6-1.7
		c-0.4-0.7-0.6-1.7-0.6-2.7c0-1,0.2-2,0.6-2.7c0.4-0.8,0.9-1.4,1.5-1.8c0.7-0.4,1.4-0.6,2.3-0.6c0.7,0,1.2,0.1,1.6,0.3
		c0.4,0.2,0.7,0.5,0.9,0.7c0.2,0.3,0.4,0.5,0.5,0.7h0.2v-1.6h1.5v10.5c0,0.9-0.2,1.6-0.6,2.2c-0.4,0.6-0.9,1-1.6,1.2
		C127.8,346.1,127,346.2,126.2,346.2z M126.2,340.5c0.6,0,1.2-0.1,1.6-0.4c0.4-0.3,0.8-0.7,1-1.2c0.2-0.5,0.3-1.2,0.3-1.9
		c0-0.7-0.1-1.4-0.3-2c-0.2-0.6-0.6-1-1-1.3c-0.4-0.3-1-0.5-1.6-0.5c-0.7,0-1.2,0.2-1.7,0.5c-0.4,0.3-0.8,0.8-1,1.4
		c-0.2,0.6-0.3,1.2-0.3,1.9c0,0.7,0.1,1.3,0.3,1.9c0.2,0.5,0.6,1,1,1.3C125,340.4,125.5,340.5,126.2,340.5z"
        />
        <path
          fill="#D9D9D9"
          d="M137.8,342.3c-1,0-1.8-0.2-2.6-0.7c-0.7-0.4-1.3-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8c0-1.1,0.2-2,0.6-2.8
		c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.5,1.5-0.7,2.4-0.7c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.8,0.9,1,1.6
		c0.3,0.7,0.4,1.5,0.4,2.4v0.7h-7.9v-1.4h6.3c0-0.6-0.1-1.1-0.3-1.5c-0.2-0.5-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4
		c-0.6,0-1.2,0.2-1.6,0.5c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1-0.4,1.6v0.9c0,0.8,0.1,1.4,0.4,2c0.3,0.5,0.6,0.9,1.1,1.2
		c0.5,0.3,1,0.4,1.7,0.4c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5s0.4-0.5,0.6-0.9l1.5,0.4c-0.2,0.5-0.4,1-0.8,1.4
		c-0.4,0.4-0.8,0.7-1.4,0.9C139.2,342.2,138.5,342.3,137.8,342.3z"
        />
        <path
          fill="#D9D9D9"
          d="M157.5,331.9c-0.1-0.7-0.4-1.2-1-1.6c-0.6-0.4-1.3-0.6-2.1-0.6c-0.6,0-1.1,0.1-1.6,0.3
		c-0.5,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.4,0.7-0.4,1.2c0,0.4,0.1,0.7,0.3,0.9c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.2,0.6,0.3,0.9,0.4
		c0.3,0.1,0.6,0.2,0.8,0.3l1.4,0.4c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.6,1,1.1c0.3,0.4,0.4,1,0.4,1.6
		c0,0.7-0.2,1.4-0.6,2c-0.4,0.6-0.9,1.1-1.7,1.4c-0.7,0.4-1.6,0.5-2.7,0.5c-1,0-1.8-0.2-2.5-0.5c-0.7-0.3-1.3-0.8-1.7-1.3
		c-0.4-0.6-0.6-1.2-0.7-2h1.7c0,0.5,0.2,0.9,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.7c0.5,0.2,1,0.2,1.5,0.2c0.6,0,1.2-0.1,1.7-0.3
		s0.9-0.5,1.2-0.9c0.3-0.4,0.4-0.8,0.4-1.3c0-0.5-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.9-0.3-1.3-0.5l-1.7-0.5
		c-1.1-0.3-1.9-0.7-2.5-1.3c-0.6-0.6-0.9-1.3-0.9-2.2c0-0.8,0.2-1.4,0.6-2c0.4-0.6,1-1,1.7-1.3c0.7-0.3,1.5-0.5,2.4-0.5
		c0.9,0,1.7,0.2,2.3,0.5c0.7,0.3,1.2,0.7,1.6,1.3c0.4,0.5,0.6,1.2,0.6,1.8H157.5z"
        />
        <path
          fill="#D9D9D9"
          d="M165.9,342.3c-1,0-1.8-0.2-2.5-0.7c-0.7-0.5-1.2-1.1-1.6-1.9c-0.4-0.8-0.6-1.7-0.6-2.7c0-1,0.2-2,0.6-2.8
		s0.9-1.4,1.6-1.9c0.7-0.5,1.5-0.7,2.4-0.7c0.7,0,1.4,0.1,1.9,0.4c0.6,0.3,1.1,0.6,1.4,1.1c0.4,0.5,0.6,1,0.7,1.7h-1.6
		c-0.1-0.5-0.4-0.9-0.8-1.2c-0.4-0.4-1-0.5-1.7-0.5c-0.6,0-1.2,0.2-1.6,0.5c-0.5,0.3-0.8,0.8-1.1,1.3c-0.3,0.6-0.4,1.3-0.4,2
		c0,0.8,0.1,1.5,0.4,2.1c0.3,0.6,0.6,1,1.1,1.4c0.5,0.3,1,0.5,1.6,0.5c0.4,0,0.8-0.1,1.1-0.2s0.6-0.3,0.8-0.6c0.2-0.3,0.4-0.6,0.5-1
		h1.6c-0.1,0.6-0.3,1.1-0.7,1.6c-0.3,0.5-0.8,0.9-1.4,1.1C167.3,342.2,166.7,342.3,165.9,342.3z"
        />
        <path
          fill="#D9D9D9"
          d="M176.4,342.3c-0.9,0-1.7-0.2-2.4-0.7c-0.7-0.4-1.2-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8
		c0-1.1,0.2-2,0.6-2.8c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.4,1.5-0.7,2.4-0.7s1.7,0.2,2.4,0.7c0.7,0.4,1.2,1.1,1.6,1.9
		c0.4,0.8,0.6,1.7,0.6,2.8c0,1.1-0.2,2-0.6,2.8c-0.4,0.8-0.9,1.4-1.6,1.8C178.1,342.1,177.3,342.3,176.4,342.3z M176.4,340.9
		c0.7,0,1.3-0.2,1.7-0.5c0.5-0.4,0.8-0.8,1-1.4c0.2-0.6,0.3-1.2,0.3-1.9s-0.1-1.3-0.3-1.9c-0.2-0.6-0.6-1.1-1-1.4
		c-0.5-0.4-1-0.5-1.7-0.5c-0.7,0-1.3,0.2-1.7,0.5s-0.8,0.8-1,1.4c-0.2,0.6-0.3,1.2-0.3,1.9s0.1,1.3,0.3,1.9c0.2,0.6,0.6,1.1,1,1.4
		S175.7,340.9,176.4,340.9z"
        />
        <path
          fill="#D9D9D9"
          d="M183.5,342.1v-10.2h1.5v1.5h0.1c0.2-0.5,0.5-0.9,1-1.2c0.5-0.3,1-0.5,1.7-0.5c0.1,0,0.3,0,0.4,0
		s0.3,0,0.4,0v1.6c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
		c-0.2,0.4-0.3,0.8-0.3,1.2v6.5H183.5z"
        />
        <path
          fill="#D9D9D9"
          d="M194.4,342.3c-1,0-1.8-0.2-2.6-0.7c-0.7-0.4-1.3-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8c0-1.1,0.2-2,0.6-2.8
		c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.5,1.5-0.7,2.4-0.7c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.8,0.9,1,1.6
		c0.3,0.7,0.4,1.5,0.4,2.4v0.7h-7.9v-1.4h6.3c0-0.6-0.1-1.1-0.3-1.5c-0.2-0.5-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4
		c-0.6,0-1.2,0.2-1.6,0.5c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1-0.4,1.6v0.9c0,0.8,0.1,1.4,0.4,2c0.3,0.5,0.6,0.9,1.1,1.2
		c0.5,0.3,1,0.4,1.7,0.4c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5s0.4-0.5,0.6-0.9l1.5,0.4c-0.2,0.5-0.4,1-0.8,1.4
		c-0.4,0.4-0.8,0.7-1.4,0.9C195.7,342.2,195.1,342.3,194.4,342.3z"
        />
        <path
          fill="#D9D9D9"
          d="M210.9,331.9v1.3h-5.5v-1.3H210.9z M207.1,342.1v-11.7c0-0.6,0.1-1.1,0.4-1.5c0.3-0.4,0.6-0.7,1.1-0.9
		c0.4-0.2,0.9-0.3,1.4-0.3c0.4,0,0.7,0,0.9,0.1s0.4,0.1,0.5,0.2l-0.5,1.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.6-0.1
		c-0.5,0-0.9,0.1-1.1,0.4c-0.2,0.3-0.3,0.6-0.3,1.2v11.3H207.1z"
        />
        <path
          fill="#D9D9D9"
          d="M216.9,342.3c-0.9,0-1.7-0.2-2.4-0.7c-0.7-0.4-1.2-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8
		c0-1.1,0.2-2,0.6-2.8c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.4,1.5-0.7,2.4-0.7s1.7,0.2,2.4,0.7c0.7,0.4,1.2,1.1,1.6,1.9
		c0.4,0.8,0.6,1.7,0.6,2.8c0,1.1-0.2,2-0.6,2.8c-0.4,0.8-0.9,1.4-1.6,1.8C218.6,342.1,217.8,342.3,216.9,342.3z M216.9,340.9
		c0.7,0,1.3-0.2,1.7-0.5c0.5-0.4,0.8-0.8,1-1.4c0.2-0.6,0.3-1.2,0.3-1.9s-0.1-1.3-0.3-1.9c-0.2-0.6-0.6-1.1-1-1.4
		c-0.5-0.4-1-0.5-1.7-0.5c-0.7,0-1.3,0.2-1.7,0.5s-0.8,0.8-1,1.4c-0.2,0.6-0.3,1.2-0.3,1.9s0.1,1.3,0.3,1.9c0.2,0.6,0.6,1.1,1,1.4
		S216.2,340.9,216.9,340.9z"
        />
        <path
          fill="#D9D9D9"
          d="M223.9,342.1v-10.2h1.5v1.5h0.1c0.2-0.5,0.5-0.9,1-1.2c0.5-0.3,1-0.5,1.7-0.5c0.1,0,0.3,0,0.4,0
		s0.3,0,0.4,0v1.6c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
		c-0.2,0.4-0.3,0.8-0.3,1.2v6.5H223.9z"
        />
        <path
          fill="#D9D9D9"
          d="M235.2,328.5h1.9l3.8,6.4h0.2l3.8-6.4h1.9l-4.9,8v5.6h-1.7v-5.6L235.2,328.5z"
        />
        <path
          fill="#D9D9D9"
          d="M251.4,342.3c-0.9,0-1.7-0.2-2.4-0.7c-0.7-0.4-1.2-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8
		c0-1.1,0.2-2,0.6-2.8c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.4,1.5-0.7,2.4-0.7s1.7,0.2,2.4,0.7c0.7,0.4,1.2,1.1,1.6,1.9
		c0.4,0.8,0.6,1.7,0.6,2.8c0,1.1-0.2,2-0.6,2.8c-0.4,0.8-0.9,1.4-1.6,1.8C253.2,342.1,252.3,342.3,251.4,342.3z M251.4,340.9
		c0.7,0,1.3-0.2,1.7-0.5c0.5-0.4,0.8-0.8,1-1.4c0.2-0.6,0.3-1.2,0.3-1.9s-0.1-1.3-0.3-1.9c-0.2-0.6-0.6-1.1-1-1.4
		c-0.5-0.4-1-0.5-1.7-0.5c-0.7,0-1.3,0.2-1.7,0.5c-0.5,0.4-0.8,0.8-1,1.4c-0.2,0.6-0.3,1.2-0.3,1.9s0.1,1.3,0.3,1.9
		c0.2,0.6,0.6,1.1,1,1.4C250.1,340.7,250.7,340.9,251.4,340.9z"
        />
        <path
          fill="#D9D9D9"
          d="M264.9,337.9v-6.1h1.6v10.2h-1.6v-1.7h-0.1c-0.2,0.5-0.6,1-1.1,1.3c-0.5,0.4-1.1,0.5-1.9,0.5
		c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.9-0.7-1.2-1.3c-0.3-0.6-0.4-1.3-0.4-2.2v-6.5h1.6v6.4c0,0.7,0.2,1.3,0.6,1.8
		c0.4,0.4,1,0.7,1.6,0.7c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.7-0.5,1-0.9C264.8,339.1,264.9,338.6,264.9,337.9z"
        />
        <path
          fill="#D9D9D9"
          d="M269.4,342.1v-10.2h1.5v1.5h0.1c0.2-0.5,0.5-0.9,1-1.2c0.5-0.3,1-0.5,1.7-0.5c0.1,0,0.3,0,0.4,0
		s0.3,0,0.4,0v1.6c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
		c-0.2,0.4-0.3,0.8-0.3,1.2v6.5H269.4z"
        />
        <path
          fill="#D9D9D9"
          d="M282.4,342.1h-1.7l5-13.7h1.7l5,13.7h-1.7l-4.1-11.5h-0.1L282.4,342.1z M282.7,336.8h7.4v1.1h-7.4V336.8z"
        />
        <path
          fill="#D9D9D9"
          d="M298.5,346.2c-0.8,0-1.4-0.1-2-0.3c-0.5-0.2-1-0.4-1.4-0.8c-0.4-0.3-0.7-0.6-0.9-1l1.3-0.9
		c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.5,0.5,0.9,0.6c0.4,0.2,0.9,0.3,1.5,0.3c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.8-1,0.8-1.9v-2.1h-0.1
		c-0.1,0.2-0.3,0.4-0.5,0.7c-0.2,0.3-0.5,0.5-0.9,0.7c-0.4,0.2-0.9,0.3-1.6,0.3c-0.8,0-1.6-0.2-2.2-0.6c-0.7-0.4-1.2-1-1.6-1.7
		c-0.4-0.7-0.6-1.7-0.6-2.7c0-1,0.2-2,0.6-2.7c0.4-0.8,0.9-1.4,1.5-1.8c0.7-0.4,1.4-0.6,2.3-0.6c0.7,0,1.2,0.1,1.6,0.3
		c0.4,0.2,0.7,0.5,0.9,0.7c0.2,0.3,0.4,0.5,0.5,0.7h0.2v-1.6h1.5v10.5c0,0.9-0.2,1.6-0.6,2.2c-0.4,0.6-0.9,1-1.6,1.2
		C300.1,346.1,299.3,346.2,298.5,346.2z M298.4,340.5c0.6,0,1.2-0.1,1.6-0.4c0.4-0.3,0.8-0.7,1-1.2c0.2-0.5,0.3-1.2,0.3-1.9
		c0-0.7-0.1-1.4-0.3-2c-0.2-0.6-0.6-1-1-1.3c-0.4-0.3-1-0.5-1.6-0.5c-0.7,0-1.2,0.2-1.7,0.5c-0.4,0.3-0.8,0.8-1,1.4
		c-0.2,0.6-0.3,1.2-0.3,1.9c0,0.7,0.1,1.3,0.3,1.9c0.2,0.5,0.6,1,1,1.3C297.2,340.4,297.8,340.5,298.4,340.5z"
        />
        <path
          fill="#D9D9D9"
          d="M310.1,342.3c-1,0-1.8-0.2-2.6-0.7c-0.7-0.4-1.3-1.1-1.6-1.8c-0.4-0.8-0.6-1.7-0.6-2.8c0-1.1,0.2-2,0.6-2.8
		c0.4-0.8,0.9-1.4,1.6-1.9c0.7-0.5,1.5-0.7,2.4-0.7c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.8,0.9,1,1.6
		c0.3,0.7,0.4,1.5,0.4,2.4v0.7h-7.9v-1.4h6.3c0-0.6-0.1-1.1-0.3-1.5s-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4
		c-0.6,0-1.2,0.2-1.6,0.5c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1-0.4,1.6v0.9c0,0.8,0.1,1.4,0.4,2c0.3,0.5,0.6,0.9,1.1,1.2
		c0.5,0.3,1,0.4,1.7,0.4c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5s0.4-0.5,0.6-0.9l1.5,0.4c-0.2,0.5-0.4,1-0.8,1.4
		c-0.4,0.4-0.8,0.7-1.4,0.9C311.4,342.2,310.8,342.3,310.1,342.3z"
        />
      </g>
      <text
        id="averageScoreValue"
        transform="matrix(1 0 0 1 195.8755 297.2481)"
        fill="#FFFFFF"
        font-family="'Inter-Bold'"
        font-size="65.0806px"
        class="bold-text"
      >
        0%
      </text>
      <g id="averageAvatar">
        <component
          :is="averageAvatarComponent"
          :key="gender + '-' + 'Average'"
          @hook:mounted="checkComponentsLoaded('averageAvatarComponent')"
        />
      </g>
      <text
        id="prospectScoreValue"
        transform="matrix(1 0 0 1 185.2436 156.3388)"
        fill="#A4D6EC"
        font-family="'Inter-Bold'"
        font-size="77.2388px"
        class="bold-text"
      >
        0%
      </text>
      <g id="prospectAvatar">
        <component
          :is="prospectAvatarComponent"
          :key="gender + '-' + age.toString()"
          @hook:mounted="checkComponentsLoaded('prospectAvatarComponent')"
        />
      </g>
      <linearGradient
        id="prospectBarGradient_00000160153878589513903800000005087199854486771598_"
        gradientUnits="userSpaceOnUse"
        x1="-248820.1094"
        y1="35.2507"
        x2="-24740.834"
        y2="35.2507"
        gradientTransform="matrix(-1.719556e-03 0 0 1 -42.3785 0)"
      >
        <stop offset="0" style="stop-color: #e42320" />
        <stop offset="0.3821" style="stop-color: #f8af3c" />
        <stop offset="0.4892" style="stop-color: #f8ce35" />
        <stop offset="0.5822" style="stop-color: #f8e131" />
        <stop offset="0.6462" style="stop-color: #f8e82f" />
        <stop offset="1" style="stop-color: #7fbb48" />
      </linearGradient>
      <rect
        id="prospectBarGradient"
        x="0.2"
        y="24"
        fill="url(#prospectBarGradient_00000160153878589513903800000005087199854486771598_)"
        width="385.3"
        height="22.4"
      />
      <polygon
        id="indicator"
        opacity="0"
        fill="#FFFFFF"
        points="0.5,29.9 -5.4,19.6 -11.4,9.4 0.5,9.4 12.3,9.4 6.4,19.6 "
      />
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  props: {
    colorScheme: {
      type: String,
      default: "dark",
      required: false,
    },
    showAverageBlurb: {
      type: Boolean,
      default: true,
      required: false,
    },
    showProspectAvatar: {
      type: Boolean,
      default: true,
      required: false,
    },
    prospectScore: {
      type: [Number, String],
      default: 81,
      required: true,
    },
    averageScore: {
      type: [Number, String],
      default: 32,
      required: false,
    },
    age: {
      type: [Number, String],
      default: 40,
      required: true,
    },
    gender: {
      type: String,
      default: "Male",
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      duration: 1.5,
      ease: "ease-out",
      componentsLoaded: {
        prospectAvatarLoaded: false,
        averageAvatarLoaded: false,
      },
    };
  },
  computed: {
    prospectAvatarComponent() {
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}Mobile.vue`
        );
    },
    averageAvatarComponent() {
      let avatarComponentName = this.gender + "AverageAvatarMobile";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
  },
  watch: {
    componentsLoaded: {
      handler(val) {
        if (val.prospectAvatarLoaded && val.averageAvatarLoaded) {
          this.loading = false;
          this.runAnimations();
        }
      },
      deep: true,
    },
  },

  methods: {
    checkComponentsLoaded(comp) {
      if (comp === "prospectAvatarComponent") {
        this.componentsLoaded.prospectAvatarLoaded = true;
      } else {
        this.componentsLoaded.averageAvatarLoaded = true;
      }
    },
    runAnimations() {
      const indicator = document.getElementById("indicator");
      const prospect_avatar = document.getElementById("prospectAvatar");
      const prospect_gradient_bar = document.getElementById(
        "prospectBarGradient"
      );
      const prospect_bar_width = prospect_gradient_bar.getBBox().width;
      const prospect_score_text = document.getElementById("prospectScoreValue");
      const prospect_indicator_location =
        (parseInt(prospect_bar_width) * this.prospectScore) / 100;

      const average_score_text = document.getElementById("averageScoreValue");

      const average_avatar = document.getElementById("averageAvatar");

      gsap.set([prospect_avatar, average_avatar], {
        scale: 0,
        transformOrigin: "50% 50%",
      });

      const prospectTL = gsap.timeline();

      prospectTL
        .to([prospect_avatar, average_avatar], {
          scale: 1,
          transformOrigin: "50% 50%",
          duration: 0.25,
          ease: "back.out(1.7)",
        })
        .to(indicator, {
          opacity: 1,
          duration: 0.5,
          ease: this.ease,
        })
        .to(
          indicator,
          {
            x: prospect_indicator_location,
            duration: this.duration - 1,
            ease: this.ease,
          },
          "-=.5"
        );

      const prospectValueTarget = { val: 0 };
      gsap.to(
        prospectValueTarget,
        {
          val: this.prospectScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            prospect_score_text.innerHTML =
              prospectValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );

      const averageValueTarget = { val: 0 };
      gsap.to(
        averageValueTarget,
        {
          val: this.averageScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            average_score_text.innerHTML =
              averageValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#Layer_1 {
  overflow: visible;
}
</style>
