<template>
  <div id="rs-howMuchLoseGraphic" class="rs-graphic-how-much-lose">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 331.6 150.8"
      enable-background="new 0 0 331.6 150.8"
      xml:space="preserve"
    >
      <svg:style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600");
        text,
        tspan {
          font-family: "Inter";
          font-weight: normal;
        }
        tspan {
          font-size: 10px;
        }
        .bold-text {
          font-weight: bold;
        }
      </svg:style>
      <g ref="lossGraphic" id="lossGraphic">
        <circle
          ref="benefitRing"
          id="benefitRing"
          fill="#92C01F"
          cx="74.9"
          cy="82.2"
          r="65.5"
        />
        <g ref="lossRing" id="lossRing">
          <path
            fill="#CC5252"
            d="M122.3,24.9c-1.2-1-2.4-2-3.7-2.9c-1.3-0.9-2.5-1.8-3.8-2.7c-1.3-0.9-2.6-1.7-4-2.4
			c-1.4-0.8-2.7-1.5-4.1-2.2s-2.8-1.3-4.3-1.9c-1.4-0.6-2.9-1.1-4.4-1.6s-3-0.9-4.5-1.4c-1.5-0.4-3-0.8-4.6-1.1
			c-1.5-0.3-3.1-0.6-4.6-0.8s-3.1-0.4-4.6-0.5c-1.6-0.1-3.1-0.2-4.7-0.2s-3.1,0-4.7,0.1s-3.1,0.2-4.7,0.4c-1.5,0.2-3.1,0.4-4.6,0.7
			s-3.1,0.6-4.6,1s-3,0.8-4.5,1.3s-3,1-4.4,1.5c-1.5,0.6-2.9,1.2-4.3,1.8c-1.4,0.6-2.8,1.3-4.2,2.1c-1.4,0.7-2.7,1.5-4,2.3
			s-2.6,1.7-3.9,2.6c-1.3,0.9-2.5,1.8-3.7,2.8s-2.4,2-3.5,3.1l50.4,54.7L125.8,28C124.7,27,123.5,25.9,122.3,24.9z"
          />
        </g>
        <g ref="moneyRing" id="moneyRing">
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="-1053.9226"
              y1="853.951"
              x2="-999.5443"
              y2="908.3296"
              gradientTransform="matrix(0.9974 -7.194301e-02 -7.194301e-02 -0.9974 1162.0824 887.0524)"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop offset="1" style="stop-color: #d9d9d9" />
            </linearGradient>
            <circle fill="url(#SVGID_1_)" cx="74.6" cy="82.1" r="38.5" />
            <circle fill="#ECECEC" cx="74.6" cy="82.1" r="34.6" />
          </g>
        </g>
        <g ref="moneyIcon" id="moneyIcon">
          <g>
            <path
              fill="#C6C697"
              d="M97.2,79.8L81,89.2l-12-6.9l3.7-2.1c2.2,0.1,4.6-0.3,6.4-1.4c1.8-1,2.6-2.4,2.4-3.7l3.7-2.1L97.2,79.8z"
            />
            <polygon
              fill="#176698"
              points="84.3,72.4 68,81.7 68,94.1 63,91.3 63,78.8 79.2,69.5"
            />
            <polygon
              fill="#C6C697"
              points="81,93.6 69,86.7 69,84.7 81,91.7 97.2,82.3 97.2,84.2"
            />
            <polygon
              fill="#C6C697"
              points="81,97.6 69,90.7 69,88.7 81,95.7 97.2,86.3 97.2,88.2"
            />
            <polygon
              fill="#C6C697"
              points="81,101.6 69,94.7 69,92.7 81,99.7 97.2,90.3 97.2,92.2"
            />
            <polygon
              fill="#C6C697"
              points="78.2,68.8 62,78.2 62,78.2 52.1,72.5 68.3,63.2"
            />
            <polygon
              fill="#C6C697"
              points="62,88.7 62,90.6 52.1,84.9 52.1,83"
            />
            <polygon
              fill="#C6C697"
              points="62,84.7 62,86.6 52.1,80.9 52.1,79"
            />
            <polygon
              fill="#C6C697"
              points="62,80.7 62,82.6 52.1,76.9 52.1,75"
            />
          </g>
        </g>

        <g ref="lossValue" id="lossValue">
          <text
            ref="lossValueText"
            transform="matrix(1 0 0 1 67.0012 33.2373)"
            fill="#FFFFFF"
            font-family="'Inter-Bold'"
            font-size="13.1041px"
            class="bold-text"
          >
            $0
          </text>
        </g>

        <line
          ref="lossLine"
          id="lossLine"
          fill="none"
          :stroke="pdfType === 'print' ? '#000' : '#FFFFFF'"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="191.7"
          y1="21.4"
          x2="117.7"
          y2="21.4"
        />

        <g
          ref="lossText"
          id="lossText"
          style="transform: translateX(-40px); opacity: 0"
        >
          <text
            ref="lossTextContent"
            transform="matrix(1 0 0 1 216.8088 12.0488)"
            font-family="'Inter-Medium'"
            font-size="10px"
          >
            {{ lossTextContent }}
          </text>
        </g>

        <line
          ref="benefitLine"
          id="benefitLine"
          fill="none"
          :stroke="pdfType === 'print' ? '#000' : '#FFFFFF'"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="193.7"
          y1="84.5"
          x2="140.2"
          y2="84.5"
        />
      </g>
      <g
        ref="benefitText"
        id="benefitText"
        style="transform: translateX(-60px); opacity: 0"
      >
        <text
          ref="benefitTextContent"
          transform="matrix(1 0 0 1 216.8088 75.4721)"
          font-family="'Inter-Medium'"
          font-size="10px"
        >
          {{ benefitTextContent }}
        </text>
      </g>
      <g ref="benefitValue" id="benefitValue">
        <text
          ref="benefitValueText"
          transform="matrix(1 0 0 1 68 135)"
          fill="#FFFFFF"
          font-family="'Inter-Bold'"
          font-size="10px"
          class="bold-text"
        >
          $0
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  props: {
    lossValue: {
      type: String,
      default: "--",
      required: true,
    },
    lossTextContent: {
      type: String,
      required: false,
      default: "Estimated loss based on government forecast",
    },
    benefitValue: {
      type: String,
      required: false,
      default: "$1M",
    },
    benefitTextContent: {
      type: String,
      required: false,
      default: "Your benefit Social Security benefit estimate",
    },
    pdfType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      lossWords: null,
    };
  },
  async mounted() {
    this.setSvgMultilineText();
    this.runAnimation();
    window.addEventListener("resize", () => {
      this.setSvgMultilineText();
    });
  },
  methods: {
    runAnimation() {
      let scrollTrigger = {
        trigger: this.$refs.lossGraphic, //"#lossGraphic",
        start: "bottom bottom",
      };

      if (this.pdfType) {
        scrollTrigger = null;
      }
      const tl = gsap.timeline({
        scrollTrigger: scrollTrigger,
      });

      // const lossValueTarget = { val: 0 };
      const loss_value_wrap = this.$refs.lossValue;
      const loss_value_text = this.$refs.lossValueText;
      loss_value_text.innerHTML = this.lossValue;
      const loss_line_x2 = this.$refs.lossLine.getAttribute("x2");
      // document.querySelector("#lossLine").getAttribute("x2");
      const benefit_value_wrap = this.$refs.benefitValue;
      const benefit_value_text = this.$refs.benefitValueText; //document.querySelector("#benefitValue text");
      const benefit_line_x2 = this.$refs.benefitLine.getAttribute("x2");
      // document.querySelector("#benefitLine").getAttribute("x2");
      benefit_value_text.innerHTML = this.benefitValue;

      tl.from(
        this.$refs.moneyRing,
        {
          scale: 0,
          transformOrigin: "50% 50%",
          duration: 0.25,
          ease: "back.out(1.7)",
        },
        "-=.1"
      )
        .from(
          this.$refs.moneyIcon,
          {
            scale: 0,
            opacity: 0,
            transformOrigin: "50% 50%",
            duration: 0.1,
          },
          "-=.15"
        )
        .from(this.$refs.benefitRing, {
          scale: 0,
          transformOrigin: "50% 50%",
          duration: 0.35,
          ease: "back.out(1.7)",
        })
        .from(
          this.$refs.lossRing,
          {
            scale: 0,
            rotate: -120,
            transformOrigin: "bottom center",
            duration: 0.5,
            ease: "back.out(1.7)",
          },
          "-=.25"
        )
        .from(this.$refs.lossLine, {
          attr: {
            x1: loss_line_x2,
          },
          duration: 0.15,
        })
        .to(
          this.$refs.lossText,
          {
            x: -20,
            opacity: 1,
            duration: 0.15,
          },
          "-=.1"
        )
        .from(this.$refs.lossValue, {
          // x: -25,
          duration: 0,
          opacity: 0,
        })
        .to(this.$refs.lossValue, {
          // x: -25,
          duration: 0,
          opacity: 0,
        })
        .from(this.$refs.benefitValue, {
          x: -5,
          duration: 0,
          opacity: 0,
        })
        .to(this.$refs.benefitValue, {
          x: -5,
          duration: 0,
          opacity: 0,
        })
        .from(
          this.$refs.benefitLine,
          {
            attr: {
              x1: benefit_line_x2,
            },
            duration: 0.15,
          },
          "-=.25"
        )
        .to(
          this.$refs.benefitText,
          {
            x: -20,
            opacity: 1,
            duration: 0.15,
          },
          "-=.25"
        )
        .to([this.$refs.lossValue, this.$refs.benefitValue], {
          opacity: 1,
          onUpdate: function () {
            const newWidth = loss_value_wrap.getBBox().width;
            const adjustWidth = -1 * (newWidth / 2.5);
            loss_value_wrap.style.transform =
              "translateX(" + adjustWidth + "px)";

            const newbenefitWidth = benefit_value_wrap.getBBox().width;
            const adjustbenefitWidth = -1 * (newbenefitWidth / 3);
            benefit_value_wrap.style.transform =
              "translateX(" + adjustbenefitWidth + "px)";
          },
        });
      // .to(
      //   lossValueTarget,
      //   {
      //     delay: 1,
      //     val: this.lossValue,
      //     duration: 1 + 0.15,
      //     ease: "linear",
      //     onUpdate: function () {
      //       loss_value_text.innerHTML =
      //         "$" + Math.ceil(lossValueTarget.val).toLocaleString();
      //       const newWidth = loss_value_wrap.getBBox().width;
      //       const adjustWidth = -1 * (newWidth / 2.5);
      //       loss_value_wrap.style.transform =
      //         "translateX(" + adjustWidth + "px)";
      //     },
      //   },
      //   0
      // );
    },
    setSvgMultilineText() {
      var y = 12;
      const textColor = this.pdfType === "print" ? "#000" : "#FFF";

      const textAdjust = (element, textContent, boxWidth) => {
        const text = textContent; //element.innerHTML;
        const width = boxWidth ? boxWidth : "300"; // element.getBBox().width + 90;
        /* split the words into array */
        const words = text.split(" ");
        let line = "";

        /* Make a tspan for testing */
        element.innerHTML = "";
        element.innerHTML =
          '<tspan fill="#fff" class="processing">loading</tspan>';
        for (let n = 0; n < words.length; n++) {
          const testLine = line + words[n] + " ";
          const testElem = document.querySelector(".processing"); // document.getElementById("PROCESSING");

          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Measure textElement */
          const metrics = testElem.getBoundingClientRect();
          const testWidth = metrics.width;

          if (testWidth > width && n > 0) {
            element.innerHTML += `<tspan fill="${textColor}" x="0" dy="${y}">${line}</tspan>`;
            // '<tspan fill="#fff" x="0" dy="' + y + '">' + line + "</tspan>";
            line = words[n] + " ";
          } else {
            line = testLine;
          }
        }

        element.innerHTML += `<tspan fill="${textColor}" x="0" dy="${y}">${line}</tspan>`;

        document.querySelector(".processing").remove();
      };

      let mq1 = window.matchMedia("(min-width: 351px) and (max-width: 479px)");

      let mq2 = window.matchMedia("(min-width: 480px) and (max-width: 549px)");

      let mq3 = window.matchMedia("(min-width: 550px) and (max-width: 599px)");

      let mq4 = window.matchMedia("(min-width: 600px) and (max-width: 1263px)");

      let mq5 = window.matchMedia("(min-width: 1264px)");

      let textBoxWidth = 100;

      if (mq1.matches) {
        textBoxWidth = 150;
      } else if (mq2.matches) {
        textBoxWidth = 175;
      } else if (mq3.matches || this.pdfType) {
        textBoxWidth = 200;
      } else if (mq4.matches) {
        textBoxWidth = 250;
      } else if (mq5.matches) {
        textBoxWidth = 300;
      }
      textAdjust(
        this.$refs.lossTextContent,
        this.lossTextContent,
        textBoxWidth
      );
      textAdjust(
        this.$refs.benefitTextContent,
        this.benefitTextContent,
        textBoxWidth
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-graphic-how-much-lose {
  position: relative;

  svg {
    overflow: visible;
  }
}

#benefitRing {
  filter: drop-shadow(0 0 10px rgba(#000, 0.3));
}
#moneyRing {
  filter: drop-shadow(0 0 8px rgba(#000, 0.3));
}
#lossRing {
  filter: drop-shadow(-10px 0 5px rgba(#000, 0.2));
}
#lossValue,
#benefitValue {
  opacity: 0;
}
</style>
