<template>
  <header class="px-4 rs-report__header" :class="pdfType ? 'mb-4' : 'mb-8'">
    <v-row class="align-center mb-4">
      <v-col :class="pdfType ? 'col-4' : 'col-12 col-lg-4'">
        <div
          class="rs-report__logo pr-4"
          :class="pdfType ? 'text-left' : 'd-flex justify-center'"
        >
          <!-- <img v-if="pdfType" src="@/assets/risk-score-logo-full-blue.svg" /> -->
          <template v-if="pdfType">
            <img
              v-if="pdfType === 'print'"
              src="@/assets/risk-score-logo-full-blue.svg"
            />
            <img
              v-if="pdfType === 'download'"
              src="@/assets/risk-score-logo-full-white.svg"
            />
          </template>

          <v-img
            v-else
            src="@/assets/risk-score-logo-full-white.svg"
            max-width="350"
          />
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        :class="pdfType ? 'col-8' : 'col-12 col-lg-6'"
        style="line-height: 1.1; word-break: break-word"
      >
        <v-row
          class="justify-center"
          :class="[
            { 'justify-lg-end': !advisorData },
            pdfType ? 'text-left' : 'text-center text-lg-left',
          ]"
        >
          <v-col class="mr-0" :class="pdfType ? 'col-6' : 'col-12 col-sm-6'">
            <template v-if="prospectName">
              <p class="mb-1 pt-2 pt-md-4 pt-lg-0">Prepared for:</p>
              <p class="mb-1 font-weight-black is-larger">
                {{ prospectName }}
              </p>
            </template>
            <p
              class="mb-1 font-weight-black"
              :class="{ 'pt-2 pt-md-4 pt-lg-0': !prospectName }"
            >
              Social Security Risk Score v{{ reportVersion }}
            </p>
            <p class="mb-1">
              {{ reportDate }}
            </p>
          </v-col>
          <v-col
            v-if="advisorData"
            :class="
              pdfType
                ? 'col-6 has-separator'
                : 'col-12 col-sm-6 pr-0 has-separator'
            "
            ><p class="mb-1 pt-2 pt-md-4 pt-lg-0">Your Risk Score Advisor:</p>
            <p class="mb-1 font-weight-black">
              {{ advisorData.firstName + " " + advisorData.lastName }}
            </p>
            <p class="mb-1">
              <a
                :href="getAdvisorEmail"
                :class="
                  !pdfType || pdfType === 'download'
                    ? 'white--text'
                    : 'black--text'
                "
                >{{ advisorData.email }}</a
              >
            </p>
            <p class="mb-1">
              <a
                :href="getAdvisorPhone"
                :class="
                  !pdfType || pdfType === 'download'
                    ? 'white--text'
                    : 'black--text'
                "
                >{{ advisorData.phone }}</a
              >
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </header>
</template>

<script>
export default {
  props: {
    advisor: {
      type: Object,
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdfType: {
      type: String,
      required: false,
      default: null,
    },
    prospectQualified: {
      type: Boolean,
      required: false,
    },
    prospectName: {
      required: false,
      default: "XXXXXXXXXX",
    },
    reportVersion: {
      required: false,
      default: "X.X",
    },
    reportDate: {
      required: false,
      default: "XXXXXXXXXX",
    },
  },
  computed: {
    advisorData() {
      // if not advisor don't show
      // if not qualified don't show
      // if advisor and qualified, show and load real data
      // if isEditing, load fake data
      let advisorData = null;

      if (this.isEditing) {
        advisorData = {
          firstName: "XXXXXXXXXX",
          lastName: "XXXXXXXXXX",
          email: "XXXXXXXXXX",
          phone: "XXXXXXXXXX",
        };
      } else if (this.advisor && this.prospectQualified) {
        advisorData = this.advisor;
      }
      return advisorData;
    },
    getAdvisorEmail() {
      return "mailto:" + this.advisorData.email;
    },
    getAdvisorPhone() {
      return "tel:" + this.advisorData.phone;
    },
  },
};
</script>
