import BaseHeading from "@tiptap/extension-heading";
import { mergeAttributes } from "@tiptap/core";
// adapted from https://github.com/ueberdosis/tiptap/issues/1514

const classes = {
  1: "text-h4 text-md-h3 text-lg-h1 font-weight-black",
  2: "text-h5 text-md-h4 text-lg-h2",
  3: "text-h6 text-md-h5 text-lg-h3",
};

const CustomHeading = BaseHeading.configure({
  levels: [1, 2, 3],
}).extend({
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];
    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: `${classes[level]}`,
      }),
      0,
    ];
  },
});

export default CustomHeading;
