/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAdvisor = /* GraphQL */ `
  mutation CreateAdvisor(
    $input: CreateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    createAdvisor(input: $input, condition: $condition) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateAdvisor = /* GraphQL */ `
  mutation UpdateAdvisor(
    $input: UpdateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    updateAdvisor(input: $input, condition: $condition) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteAdvisor = /* GraphQL */ `
  mutation DeleteAdvisor(
    $input: DeleteAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    deleteAdvisor(input: $input, condition: $condition) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createAdvisorVanityName = /* GraphQL */ `
  mutation CreateAdvisorVanityName(
    $input: CreateAdvisorVanityNameInput!
    $condition: ModelAdvisorVanityNameConditionInput
  ) {
    createAdvisorVanityName(input: $input, condition: $condition) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateAdvisorVanityName = /* GraphQL */ `
  mutation UpdateAdvisorVanityName(
    $input: UpdateAdvisorVanityNameInput!
    $condition: ModelAdvisorVanityNameConditionInput
  ) {
    updateAdvisorVanityName(input: $input, condition: $condition) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteAdvisorVanityName = /* GraphQL */ `
  mutation DeleteAdvisorVanityName(
    $input: DeleteAdvisorVanityNameInput!
    $condition: ModelAdvisorVanityNameConditionInput
  ) {
    deleteAdvisorVanityName(input: $input, condition: $condition) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteProspect = /* GraphQL */ `
  mutation DeleteProspect(
    $input: DeleteProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    deleteProspect(input: $input, condition: $condition) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const createReportTemplate = /* GraphQL */ `
  mutation CreateReportTemplate(
    $input: CreateReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    createReportTemplate(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const updateReportTemplate = /* GraphQL */ `
  mutation UpdateReportTemplate(
    $input: UpdateReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    updateReportTemplate(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const deleteReportTemplate = /* GraphQL */ `
  mutation DeleteReportTemplate(
    $input: DeleteReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    deleteReportTemplate(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const createIntelOrg = /* GraphQL */ `
  mutation CreateIntelOrg(
    $input: CreateIntelOrgInput!
    $condition: ModelIntelOrgConditionInput
  ) {
    createIntelOrg(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIntelOrg = /* GraphQL */ `
  mutation UpdateIntelOrg(
    $input: UpdateIntelOrgInput!
    $condition: ModelIntelOrgConditionInput
  ) {
    updateIntelOrg(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIntelOrg = /* GraphQL */ `
  mutation DeleteIntelOrg(
    $input: DeleteIntelOrgInput!
    $condition: ModelIntelOrgConditionInput
  ) {
    deleteIntelOrg(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIntelGroup = /* GraphQL */ `
  mutation CreateIntelGroup(
    $input: CreateIntelGroupInput!
    $condition: ModelIntelGroupConditionInput
  ) {
    createIntelGroup(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIntelGroup = /* GraphQL */ `
  mutation UpdateIntelGroup(
    $input: UpdateIntelGroupInput!
    $condition: ModelIntelGroupConditionInput
  ) {
    updateIntelGroup(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIntelGroup = /* GraphQL */ `
  mutation DeleteIntelGroup(
    $input: DeleteIntelGroupInput!
    $condition: ModelIntelGroupConditionInput
  ) {
    deleteIntelGroup(input: $input, condition: $condition) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScoreSurveyQuestion = /* GraphQL */ `
  mutation CreateScoreSurveyQuestion(
    $input: CreateScoreSurveyQuestionInput!
    $condition: ModelScoreSurveyQuestionConditionInput
  ) {
    createScoreSurveyQuestion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const updateScoreSurveyQuestion = /* GraphQL */ `
  mutation UpdateScoreSurveyQuestion(
    $input: UpdateScoreSurveyQuestionInput!
    $condition: ModelScoreSurveyQuestionConditionInput
  ) {
    updateScoreSurveyQuestion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const deleteScoreSurveyQuestion = /* GraphQL */ `
  mutation DeleteScoreSurveyQuestion(
    $input: DeleteScoreSurveyQuestionInput!
    $condition: ModelScoreSurveyQuestionConditionInput
  ) {
    deleteScoreSurveyQuestion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const createProspect = /* GraphQL */ `
  mutation CreateProspect(
    $input: CreateProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    createProspect(input: $input, condition: $condition) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const updateProspect = /* GraphQL */ `
  mutation UpdateProspect(
    $input: UpdateProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    updateProspect(input: $input, condition: $condition) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
