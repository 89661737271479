var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"px-4 rs-report__header",class:_vm.pdfType ? 'mb-4' : 'mb-8'},[_c('v-row',{staticClass:"align-center mb-4"},[_c('v-col',{class:_vm.pdfType ? 'col-4' : 'col-12 col-lg-4'},[_c('div',{staticClass:"rs-report__logo pr-4",class:_vm.pdfType ? 'text-left' : 'd-flex justify-center'},[(_vm.pdfType)?[(_vm.pdfType === 'print')?_c('img',{attrs:{"src":require("@/assets/risk-score-logo-full-blue.svg")}}):_vm._e(),(_vm.pdfType === 'download')?_c('img',{attrs:{"src":require("@/assets/risk-score-logo-full-white.svg")}}):_vm._e()]:_c('v-img',{attrs:{"src":require("@/assets/risk-score-logo-full-white.svg"),"max-width":"350"}})],2)]),_c('v-spacer'),_c('v-col',{class:_vm.pdfType ? 'col-8' : 'col-12 col-lg-6',staticStyle:{"line-height":"1.1","word-break":"break-word"}},[_c('v-row',{staticClass:"justify-center",class:[
          { 'justify-lg-end': !_vm.advisorData },
          _vm.pdfType ? 'text-left' : 'text-center text-lg-left' ]},[_c('v-col',{staticClass:"mr-0",class:_vm.pdfType ? 'col-6' : 'col-12 col-sm-6'},[(_vm.prospectName)?[_c('p',{staticClass:"mb-1 pt-2 pt-md-4 pt-lg-0"},[_vm._v("Prepared for:")]),_c('p',{staticClass:"mb-1 font-weight-black is-larger"},[_vm._v(" "+_vm._s(_vm.prospectName)+" ")])]:_vm._e(),_c('p',{staticClass:"mb-1 font-weight-black",class:{ 'pt-2 pt-md-4 pt-lg-0': !_vm.prospectName }},[_vm._v(" Social Security Risk Score v"+_vm._s(_vm.reportVersion)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.reportDate)+" ")])],2),(_vm.advisorData)?_c('v-col',{class:_vm.pdfType
              ? 'col-6 has-separator'
              : 'col-12 col-sm-6 pr-0 has-separator'},[_c('p',{staticClass:"mb-1 pt-2 pt-md-4 pt-lg-0"},[_vm._v("Your Risk Score Advisor:")]),_c('p',{staticClass:"mb-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm.advisorData.firstName + " " + _vm.advisorData.lastName)+" ")]),_c('p',{staticClass:"mb-1"},[_c('a',{class:!_vm.pdfType || _vm.pdfType === 'download'
                  ? 'white--text'
                  : 'black--text',attrs:{"href":_vm.getAdvisorEmail}},[_vm._v(_vm._s(_vm.advisorData.email))])]),_c('p',{staticClass:"mb-1"},[_c('a',{class:!_vm.pdfType || _vm.pdfType === 'download'
                  ? 'white--text'
                  : 'black--text',attrs:{"href":_vm.getAdvisorPhone}},[_vm._v(_vm._s(_vm.advisorData.phone))])])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }