/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIntelOrg = /* GraphQL */ `
  query GetIntelOrg($id: ID!) {
    getIntelOrg(id: $id) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIntelOrgs = /* GraphQL */ `
  query ListIntelOrgs(
    $filter: ModelIntelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntelOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        groups {
          items {
            id
            title
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            website
            phone
            intelOrgId
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            metrics {
              title
              ranking
              sales
              active_advisors
              leader_percentage
              trend
              total_prospects
              new_prospects
              __typename
            }
            aum
            recProductsCount
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          id
          title
          type
          image
          excerpt
          tags
          active
          action_text
          __typename
        }
        website
        phone
        aum
        recProductsCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIntelGroup = /* GraphQL */ `
  query GetIntelGroup($id: ID!) {
    getIntelGroup(id: $id) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIntelGroups = /* GraphQL */ `
  query ListIntelGroups(
    $filter: ModelIntelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntelGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        website
        phone
        intelOrgId
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        metrics {
          title
          ranking
          sales
          active_advisors
          leader_percentage
          trend
          total_prospects
          new_prospects
          __typename
        }
        aum
        recProductsCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrganizations = /* GraphQL */ `
  query SearchOrganizations(
    $filter: SearchableOrganizationFilterInput
    $sort: [SearchableOrganizationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganizationAggregationInput]
  ) {
    searchOrganizations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAdvisor = /* GraphQL */ `
  query GetAdvisor($id: ID!) {
    getAdvisor(id: $id) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listAdvisors = /* GraphQL */ `
  query ListAdvisors(
    $filter: ModelAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAdvisors = /* GraphQL */ `
  query SearchAdvisors(
    $filter: SearchableAdvisorFilterInput
    $sort: [SearchableAdvisorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorAggregationInput]
  ) {
    searchAdvisors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAdvisorVanityName = /* GraphQL */ `
  query GetAdvisorVanityName($id: ID!) {
    getAdvisorVanityName(id: $id) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listAdvisorVanityNames = /* GraphQL */ `
  query ListAdvisorVanityNames(
    $filter: ModelAdvisorVanityNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisorVanityNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        vanityName
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vanityInfoByVanityName = /* GraphQL */ `
  query VanityInfoByVanityName(
    $vanityName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdvisorVanityNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vanityInfoByVanityName(
      vanityName: $vanityName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        vanityName
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAdvisorVanityNames = /* GraphQL */ `
  query SearchAdvisorVanityNames(
    $filter: SearchableAdvisorVanityNameFilterInput
    $sort: [SearchableAdvisorVanityNameSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorVanityNameAggregationInput]
  ) {
    searchAdvisorVanityNames(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        vanityName
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProspect = /* GraphQL */ `
  query GetProspect($id: ID!) {
    getProspect(id: $id) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const listProspects = /* GraphQL */ `
  query ListProspects(
    $filter: ModelProspectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProspects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sendReport
        firstName
        lastName
        email
        phone
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        advisorNotified
        advisorVanityName
        isAnonymous
        lastScoreStep
        subscribed
        scoreSurveyAnswers
        scoreSurveyComplete
        scoreSurveyResults
        scoreSurveySource
        scoreAgreeTerms
        scoreContactComplete
        createdAt
        updatedAt
        origin
        qualified
        ctaRequests {
          id
          name
          createdAt
          updatedAt
          source
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const prospectsByDate = /* GraphQL */ `
  query ProspectsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProspectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prospectsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sendReport
        firstName
        lastName
        email
        phone
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        advisorNotified
        advisorVanityName
        isAnonymous
        lastScoreStep
        subscribed
        scoreSurveyAnswers
        scoreSurveyComplete
        scoreSurveyResults
        scoreSurveySource
        scoreAgreeTerms
        scoreContactComplete
        createdAt
        updatedAt
        origin
        qualified
        ctaRequests {
          id
          name
          createdAt
          updatedAt
          source
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const prospectsBySendReport = /* GraphQL */ `
  query ProspectsBySendReport(
    $sendReport: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProspectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prospectsBySendReport(
      sendReport: $sendReport
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sendReport
        firstName
        lastName
        email
        phone
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        advisorNotified
        advisorVanityName
        isAnonymous
        lastScoreStep
        subscribed
        scoreSurveyAnswers
        scoreSurveyComplete
        scoreSurveyResults
        scoreSurveySource
        scoreAgreeTerms
        scoreContactComplete
        createdAt
        updatedAt
        origin
        qualified
        ctaRequests {
          id
          name
          createdAt
          updatedAt
          source
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProspects = /* GraphQL */ `
  query SearchProspects(
    $filter: SearchableProspectFilterInput
    $sort: [SearchableProspectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProspectAggregationInput]
  ) {
    searchProspects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        sendReport
        firstName
        lastName
        email
        phone
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        advisorNotified
        advisorVanityName
        isAnonymous
        lastScoreStep
        subscribed
        scoreSurveyAnswers
        scoreSurveyComplete
        scoreSurveyResults
        scoreSurveySource
        scoreAgreeTerms
        scoreContactComplete
        createdAt
        updatedAt
        origin
        qualified
        ctaRequests {
          id
          name
          createdAt
          updatedAt
          source
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getReportTemplate = /* GraphQL */ `
  query GetReportTemplate($id: ID!) {
    getReportTemplate(id: $id) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const listReportTemplates = /* GraphQL */ `
  query ListReportTemplates(
    $filter: ModelReportTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        template
        version
        name
        data
        current
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScoreSurveyQuestion = /* GraphQL */ `
  query GetScoreSurveyQuestion($id: ID!) {
    getScoreSurveyQuestion(id: $id) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const listScoreSurveyQuestions = /* GraphQL */ `
  query ListScoreSurveyQuestions(
    $filter: ModelScoreSurveyQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreSurveyQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        componentName
        title
        description
        icon
        disabled
        valid
        value
        active
        type
        helpText {
          label
          text
          __typename
        }
        scoringParam
        category
        options
        skipFocus
        test
        __typename
      }
      nextToken
      __typename
    }
  }
`;
